.dtcols .col-xs-12 {
  width: 100% !important;
}
.transparent-bg div[class^='MuiPaper-'] {
  /*background-image: linear-gradient(to top, #EDF2F4, #8D99AE, #2B2D42);*/
  background-color: #EDF2F4;
  /*box-shadow: none;*/
}
* p[class^='MuiTypography'].header {
  text-transform: uppercase;
  font-size: 20px;
  padding-top: 5px;
}
.table-datatable {
  width: 100% !important;
  min-width: 100% !important;
}
.table-datatable:parent {
  width: 100% !important;
}
.table-datatable .thead-th-default.sortable:hover {
  background: #ddd !important;
  cursor: pointer !important;
}
.react-html5-camera-photo #container-circles {
  top: 10px !important;
  right: 50px !important;
  cursor: pointer;
  left: inherit;
  bottom: inherit;
}
.react-html5-camera-photo {
  height: 400px;
}
.colorFullAppBar {
  background-image: linear-gradient(to right, #2B2D42, #2B2D42, #8D99AE, #8D99AE, #8D99AE, #EDF2F4, #EDF2F4);
  /*background-color: #2B2D42 !important;*/
  color: #EDF2F4 !important;
}
.selectChoice {
  background-color: #f4511e;
  border-radius: 50%;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  transition: 0.3s;
  border: 20px solid transparent;
  border-image: url(border.png) 10% stretch;
  -webkit-border-image: url(/static/media/border.550819b8.png) 10% stretch;
}
.selectChoice img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 10px;
}

.touchMeBtn {
  animation: bounce 1s;
  animation-iteration-count: infinite;
}
.selectChoice:hover {
  animation: bounce 1s;
  animation-iteration-count: infinite;
}
@keyframes bounce {
 0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
 40% {transform: translateY(-30px);} 
 60% {transform: translateY(-15px);} 
} 

body {
  overflow: hidden;
}

@-webkit-keyframes AnimationName {
  0%{background-position:50% 0%}
  50%{background-position:50% 100%}
  100%{background-position:50% 0%}
}
@-moz-keyframes AnimationName {
  0%{background-position:50% 0%}
  50%{background-position:50% 100%}
  100%{background-position:50% 0%}
}
@keyframes AnimationName { 
  0%{background-position:50% 0%}
  50%{background-position:50% 100%}
  100%{background-position:50% 0%}
}

.App {
  text-align: center;
  /*background-image: linear-gradient(#8D99AE, #EDF2F4);*/
  background-image: linear-gradient(to bottom, #EDF2F4, #8D99AE, #2B2D42);
  /*background-color: #8D99AE;*/

  /*background: linear-gradient(360deg, #EDF2F4, #8D99AE, #2B2D42);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 12s ease infinite;
  -moz-animation: AnimationName 12s ease infinite;
  animation: AnimationName 12s ease infinite;*/

  height: 100vh;
}
.qrCodeScanner section {
  padding-top: 0 !important;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
